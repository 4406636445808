<template>
    <v-dialog
        v-model="dialog"
        max-width="600"
    >
        
        <v-toolbar
            color="green"
            dark
            flat
        >
            <v-toolbar-title>
                Catálogo de procedimentos
            </v-toolbar-title>
            <v-spacer/>
            <v-btn icon @click="dialog=false">
                <v-icon>mdi-close-box</v-icon>
            </v-btn>
        </v-toolbar>

        <v-card>
            <v-card-text>
                <v-text-field
                    class="pt-5"
                    v-model="query"
                    label="Procedimiento"
                    rounded
                    filled
                    dense
                    clearable
                />
                <v-data-table
                    elevation="2"
                    :headers="headers"
                    :items="proced"
                    disable-sort
                    :footer-props="{
                        'items-per-page-options': [10, 20, 30, 40, 50]
                    }"
                    
                    item-key="id"
                    :loading="loadingProcs"

                    :server-items-length='total'
                    :options.sync="optionsTable"
                    disable-pagination
                    hide-default-footer
                    
                >
                    <template v-slot:[`item.accion`]="{ item }">
                        <v-icon @click='selecciona(item)'>mdi-check-bold</v-icon>
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>

    </v-dialog>
</template>

<script>
export default {
    props:{
        dialogCatalogo:Boolean,
        procedimientos:Array,
        procedimientosList:Object,
    },

    data:()=>({
        query:'',
        headers:[
            { text: 'Procedimiento', value: 'procedimiento', width:'90%'},
            { text: '', value: 'accion', align:'center', width:'10%'},
        ],
        loadingProcs:false,
        total:0,
        optionsTable:{},
    }),
    methods:{
        selecciona(itm){
            this.$emit("update:procedimientosList", itm)
            this.dialog=false
        },
        async cargaProcs(){
            //this.itemsUnidad=[]
            if (!this.loadingProcs){
                this.loadingProcs = true
                
                try{
                    let consulta = await this.$http({
                        url:'/catalogos/catProcEnf',
                        method:'GET',
                        params:{
                            query:this.query,
                        },
                    })
                    this.proced=consulta.data
                    this.total=consulta.data.length
                    this.loadingProcs = false
                }catch(err){
                    this.loadingProcs = false
                    this.$store.dispatch('security/revisaError',err)
                }
            }
        },
    },
    computed:{
        dialog:{
            get(){
                return this.dialogCatalogo
            },
            set(val){
                this.$emit('update:dialogCatalogo',val)
            },

        },
        proced:{
            get(){
                return this.procedimientos
            },
            set(val){
                this.$emit('update:procedimientos',val)
            },  
        },
    },
    watch:{
         dialog(v){
            if (v){
                this.query=''
                this.cargaProcs()
            }
        },
        query(){
            this.cargaProcs()
        },
    },
        
}
</script>

<style>

</style>